<script setup>
import PrimaryButton from "@/Components/PrimaryButton.vue";
defineProps(["project"]);
</script>

<template>
    <div>
        <div class="rounded-xl overflow-hidden shadow-lg w-80" @click="goToProject" v-touch:swipe.left="nextSlide" v-touch:swipe.right="prevSlide">
            <div class="w-80 h-80 overflow-hidden">
<!--                Show the first x images-->
                <div v-for="(image, index) in images" :key="index">
                    <img class="object-cover w-full h-full" :src="image" :alt="project.naam" v-if="currentIndex === index">

                </div>

            </div>
            <!--                    Index with dots-->
            <div class="flex justify-center mt-2">
                <div v-for="(image, index) in images" :key="index">
                    <div class="w-2 h-2 rounded-full bg-gray-400 mx-1" :class="{'bg-gray-800': currentIndex === index}"></div>
                </div>
            </div>
            <div class="px-6 py-4">
                <div class="font-bold text-xl text-center">{{project.naam}}</div>
                <div class="flex flex-row mb-2">
                    <div class="inline-flex rounded-full px-3 py-1 text-sm font-semibold text-gray-100"
                   :style="{ backgroundColor: project.categorie.kleur }">
                        <p class="mt-1.5">
                            {{project.categorie.naam}}
                        </p>
                    </div>
                    <span class="flex-1"></span>
                    <div class="inline-flex px-3 py-1 text-sm font-semibold text-gray-700">
                        <p class="mt-1.5">
                            {{new Date(project.created_at).toLocaleDateString()}}
                        </p>
                    </div>
                </div>

                <p class="text-gray-700 text-base mt-2">
                    {{project.korte_beschrijving}}
                </p>

                <div class="flex flex-col justify-center mt-2">
                    <primary-button @click="goToProject">Lees meer</primary-button>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
export default {
    name: "ProjectCard",
    methods: {
        goToProject() {
            this.$inertia.visit('/projecten/' + this.project.id);
        },
        async getImagePath() {
            axios.get(route("ProjectImages", { id: this.project.id })).then(response => {
                this.images = response.data.afbeeldingen;
            //     only show the first 3 images
                this.images = this.images.slice(0, this.maxImages);
            }).catch(error => {
                console.log(error);
            })
        },
        prevSlide() {
            this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
                    //     Reset the interval
            clearInterval(this.interval);
            this.interval = setInterval(() => {
                this.nextSlide();
            }, 15000);
        },
        nextSlide() {
            this.currentIndex = (this.currentIndex + 1) % this.images.length;
        //     Reset the interval
            clearInterval(this.interval);
            this.interval = setInterval(() => {
                this.nextSlide();
            }, 15000);
        }
    },
    data() {
        return {
            images: [],
            currentIndex: 0,
            maxImages: 5
        }
    },
    mounted() {
        this.getImagePath();

        this.interval = setInterval(() => {
            this.currentIndex++;
            if (this.currentIndex >= this.images.length) {
                this.currentIndex = 0;
            }
        }, 75000);
    }
}
</script>

<style scoped>

button{
    @apply bg-donkerblauw;
}


</style>
