<script setup>
import ProjectCard from "@/Components/Home/ProjectCard.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";

defineProps(["projecten", "bekijkMeer", "selectedCategorie"]);
</script>

<template>

        <div class="py-14 md:mx-32">
            <h2>Projecten</h2>

    <!--        Filteropties    -->
            <div v-if="!bekijkMeer" class="pb-4 ">
                <ul class="flex flex-row flex-wrap justify-center gap-4">
                    <li v-for="categorie in categorieen">
                        <div class="inline-flex rounded-full px-3 py-1 text-sm font-semibold text-gray-100 cursor-pointer"
                       :style="{ backgroundColor: categorie.huidigeKleur }" @click="toggleFilter(categorie)">
                            <p class="mt-1.5">
                                {{categorie.naam}}
                            </p>
                        </div>
                    </li>
                </ul>
            </div>

            <div>
                <p v-if="projectenFiltered.length === 0" class="text-lg text-center">Helaas! Er zijn geen projecten gevonden.</p>
                <ul class="flex flex-col md:flex-row justify-center gap-8 flex-wrap" v-else>
                    <li v-for="project in projectenFiltered" :key="project.id" class="flex flex-row justify-center">
                        <ProjectCard :project="project"/>
                    </li>
                </ul>
            </div>

            <div class="mt-8 flex flex-row justify-center" v-if="bekijkMeer">
                <PrimaryButton class="w-96 h-14" @click="meer()">Bekijk meer projecten</PrimaryButton>
            </div>
        </div>

</template>

<script>
export default {
    name: "ProjectSlideshow",
    methods: {
        meer() {
        //     GOTO /projecten
            window.location.href = "/projecten";
        },
        toggleFilter(categorie){
            // Disable all categories
            for (let i = 0; i < this.categorieen.length; i++) {
                this.categorieen[i].selected = false;
                this.categorieen[i].huidigeKleur = "#525252";
            }

            categorie.selected = !categorie.selected;

            if (categorie.selected) {
                categorie.huidigeKleur = categorie.kleur;
            } else {
                categorie.huidigeKleur = "#525252";
            }
        },
        ingestCategorieen() {
            let categorieen = [];

            for (let i = 0; i < this.projecten.length; i++) {
                let exists = false;
                for(let j = 0; j < categorieen.length; j++) {
                    if (categorieen[j].id === this.projecten[i].categorie.id) {
                        exists = true;
                    }
                }
                if (!exists) {
                    let categorie = this.projecten[i].categorie;
                    categorie.selected = true;
                    categorie.huidigeKleur = categorie.kleur;
                    categorieen.push(this.projecten[i].categorie);
                }
            }

            console.log(this.selectedCategorie);
            if (typeof this.selectedCategorie !== "undefined"){
                for (let i = 0; i < categorieen.length; i++) {
                    if (categorieen[i].id === this.selectedCategorie.id) {
                        categorieen[i].selected = true;
                        categorieen[i].huidigeKleur = this.selectedCategorie.kleur;
                    } else {
                        categorieen[i].selected = false;
                        categorieen[i].huidigeKleur = "#525252";
                    }
                }
            }

            this.categorieen = categorieen;
        }
    },
    computed: {
        projectenFiltered() {
        //     Get the categories where selected = true and show only those projects
            let projecten = [];
            for (let i = 0; i < this.categorieen.length; i++) {
                if (this.categorieen[i].selected) {
                    for (let j = 0; j < this.projecten.length; j++) {
                        if (this.projecten[j].categorie.id === this.categorieen[i].id) {
                            projecten.push(this.projecten[j]);
                        }
                    }
                }
            }
            return projecten;
        }
    },
    mounted() {
        this.ingestCategorieen();
    },
    data() {
        return {
            categorieen: [],
        }
    }
}
</script>

<style scoped>

h2 {
    @apply text-4xl;
    @apply font-bold;
    @apply text-donkerblauw;
    @apply text-center;
    @apply my-8;
}

</style>
